<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row title-row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <h4 class="card-title mb-4">Listed Coin</h4>
                </div>
                <!--col-xl-6 col-lg-6 col-md-6--->

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="add-btn-box">
                    <router-link to="/list_crypto/addcoin" class="addbtn"
                      >Add Coin</router-link
                    >
                  </div>
                </div>
                <!--col-xl-6 col-lg-6 col-md-6-->
              </div>
              <!--row title-row-->

              <b-tabs
                pills
                nav-class="bg-light rounded crypto_tabs_users"
                content-class="mt-4"
              >
                <b-tab
                  v-for="(item, index) in currencyList"
                  :key="index"
                  :title="item.currency"
                  :active="item.currency == 'USDT' ? true : false"
                >
                  <b-card-text>
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label
                            class="d-inline-flex align-items-center fw-normal"
                          >
                            Show&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                              class="form-control form-select form-select-sm"
                            ></b-form-select
                            >&nbsp;entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              class="form-control form-control-sm ms-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive table-hover mb-0">
                      <b-table
                        :items="allCrypto[item.currency]"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="isBusy"
                        :show-empty="allCrypto[item.currency] ? false : true"
                      >
                        <template #empty>
                          <div class="d-flex justify-content-center mb-3">
                            No Record Found
                          </div>
                        </template>

                        <template #table-busy>
                          <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <!-- <strong>Loading...</strong> -->
                          </div>
                        </template>

                        <template v-slot:cell(image)="row_data">
                          <img
                            class="rounded-circle avatar-xs"
                            :src="row_data.item.image"
                            alt="icon"
                          />
                        </template>

                        <!-- Buy section -->
                        <template v-slot:cell(buy)="data">
                          <div
                            class="form-check form-switch form-switch-md mb-3"
                          >
                            <input
                              v-model="data.item.buy"
                              @change="check(data.item, 'buy')"
                              class="form-check-input"
                              type="checkbox"
                              id="SwitchCheckSizemd"
                            />
                          </div>
                        </template>

                        <!-- Sell section -->
                        <template v-slot:cell(sell)="data">
                          <div
                            class="form-check form-switch form-switch-md mb-3"
                          >
                            <input
                              v-model="data.item.sell"
                              @change="check(data.item, 'sell')"
                              class="form-check-input"
                              type="checkbox"
                              id="SwitchCheckSizemd"
                            />
                          </div>
                        </template>

                        <!-- Deposit section -->
                        <!-- <template v-slot:cell(deposit)="data">
                          <div
                            class="form-check form-switch form-switch-md mb-3"
                          >
                            <input
                              v-model="data.item.deposit"
                              @change="check(data.item, 'deposit')"
                              class="form-check-input"
                              type="checkbox"
                              id="SwitchCheckSizemd"
                            />
                          </div>
                        </template> -->

                        <!-- Withdraw section -->
                        <!-- <template v-slot:cell(withdraw)="data">
                          <div
                            class="form-check form-switch form-switch-md mb-3"
                          >
                            <input
                              v-model="data.item.withdraw"
                              @change="check(data.item, 'withdraw')"
                              class="form-check-input"
                              type="checkbox"
                              id="SwitchCheckSizemd"
                            />
                          </div>
                        </template> -->

                        <!-- Active Status section -->
                        <template v-slot:cell(active_status)="data">
                          <div
                            class="form-check form-switch form-switch-md mb-3"
                          >
                            <input
                              v-model="data.item.active_status"
                              @change="check(data.item, 'active_status')"
                              class="form-check-input"
                              type="checkbox"
                              id="SwitchCheckSizemd"
                            />
                          </div>
                        </template>

                        <!-- action section -->
                        <template v-slot:cell(action)="data">
                          <router-link :to="'updatecoin/' + data.item.id"
                            >Edit</router-link
                          >
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-end
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="
                                allCrypto[item.currency]
                                  ? allCrypto[item.currency].length
                                  : ''
                              "
                              :per-page="perPage"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ApiClass from "../../api/api";
import currencyList from "../../jsonFiles/currencyList.json";
export default {
  page: {
    title: "ListedCoin",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      allCrypto: [],
      currencyList: currencyList,
      isBusy: true,
      checkTrue: true,
      checkRes: true,
      checkFalse: false,

      title: "Listed Coin",
      items: [
        {
          text: "Coin",
          href: "#",
        },
        {
          text: "Listed Coin",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "image",
          sortable: false,
        },
        {
          key: "currency",
          sortable: false,
        },
        {
          key: "buy",
          sortable: false,
        },
        {
          key: "sell",
          sortable: false,
        },
        // {
        //   key: "deposit",
        //   sortable: false,
        // },
        // {
        //   key: "withdraw",
        //   sortable: false,
        // },
        {
          key: "active_status",
          sortable: false,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.usersCrypto();
  },
  methods: {
    async check(e, colm) {
      // var status = e.active_status == true ? 1 :0;
      await ApiClass.updateRequest(
        "admin/list-coins/update_status/" + e.id + "/" + e[colm] + "/" + colm,
        true
      );
    },
    async usersCrypto() {
      var result = await ApiClass.getRequest(
        "admin/list-coins/pairs/get",
        true
      );
      if (result.data.status_code == 1) {
        this.isBusy = false;
        this.allCrypto = result.data.data;
        // console.log("allCrypto=", this.allCrypto);
      }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.add-btn-box {
  text-align: right;
}
.add-btn-box a.addbtn {
  background-color: #556ee6;
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
}
</style>
